import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth/authSlice";
import dashboardSlice from "./dashboard/dashboardSlice";
import podcastSlice from "./podcast/podcastSlice";
import episodeSlice from "./episode/episodeSlice";
import categoriesSlice from "./category/categorySlice";
import channelSlice from "./channel/channelSlice";
import userProfileSlice from "./userProfile/userProfileSlice";
import sponsorSlice from "./sponsors/sponsorSlice";

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    dashboard: dashboardSlice,
    podcast: podcastSlice,
    episode: episodeSlice,
    categories: categoriesSlice,
    channel: channelSlice,
    userProfile: userProfileSlice,
    sponsor: sponsorSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
