import React from "react";

export default function Button({
  title,
  className = "",
  isLoading,
  btnType = "outlined",
  icon,
  pCss,
  ...props
}) {
  return (
    <div className="btn-hover p-0.5 rounded-[57px]">
      <button
        className={`font-ibm text-base ${
          btnType === "filled"
            ? "bg-transparent bg-white hover:text-white "
            : "text-white"
        } hover:bg-transparent font-semibold  px-4 hover:border-transparent hover:text-white rounded-[57px] ${className} ${
          pCss ?? "py-2"
        } `}
        {...props}
      >
        <span className={`${icon ? "flex items-center gap-3" : ""}`}>
          {isLoading ? "Loading..." : title}
          {icon && <span>{icon}</span>}
        </span>
      </button>
    </div>
  );
}
