import React, { useState } from "react";
import Signup from "./Signup";
import VerifyEmail from "./VerifyEmail";
import SuccessMessage from "./SuccessMessage";
import VerifyPhone from "./VerifyPhone";
import EmailVerified from "./EmailVerified";

export const ThemeContext = React.createContext();
export default function Register() {
  const [componentValue, setComponentValue] = useState(1);
  const [info, setinfo] = useState();
  let data = {
    setComponentValue: setComponentValue,
  };
  const renerViewBaseOnValue = (componentValue) => {
    let component;
    switch (componentValue) {
      case 1:
        component = <Signup {...data} />;
        break;
      case 2:
        component = <VerifyEmail {...data} />;
        break;
      case 3:
        component = <EmailVerified {...data} />;
        break;
      case 4:
        component = <VerifyPhone {...data} />;
        break;
      case 5:
        component = <SuccessMessage {...data} />;
        break;
      default:
    }
    return component;
  };

  // main view

  return (
    <>
      <ThemeContext.Provider value={[info, setinfo]}>
        {renerViewBaseOnValue(componentValue)}
      </ThemeContext.Provider>
    </>
  );
}
