import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
import { headerValue } from "./../podcast/podcastSlice";
import { getCleanQueryString } from "../channel/channelSlice";

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (_, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(`categories/`, headers);
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const updateCategories = createAsyncThunk(
  "categories/updateCategories",
  async ({ categories_title, uuid }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.put(`categories/${uuid}/`, {
        categories_title,
      });

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addCategories = createAsyncThunk(
  "categories/addCategories",
  async ({ category_name }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`categories/`, {
        category_name,
      });

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteCategories = createAsyncThunk(
  "categories/deleteCategories",
  async ({ uuid }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.delete(`categories/${uuid}/`);

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    categories: { isLoading: false, data: [], isRejected: false },
    updateCategories: { isLoading: false, data: [], isRejected: false },
    deleteCategories: { isLoading: false, isRejected: false },
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
  },
  extraReducers: {
    [getCategories.pending]: (state) => {
      state.categories.isLoading = true;
      state.categories.isRejected = false;
    },
    [getCategories.fulfilled]: (state, { payload }) => {
      state.categories.isLoading = false;
      state.categories.data = payload?.data;
      state.categories.isRejected = false;
    },
    [getCategories.rejected]: (state) => {
      state.categories.isLoading = false;
      state.categories.isRejected = false;
    },
    [addCategories.pending]: (state) => {
      state.updateCategories.isLoading = true;
      state.updateCategories.isRejected = false;
    },
    [addCategories.fulfilled]: (state, { payload }) => {
      state.updateCategories.isLoading = false;
      state.categories.data = [payload.data, ...state.categories.data];
      state.updateCategories.isRejected = false;
    },
    [addCategories.rejected]: (state) => {
      state.updateCategories.isLoading = false;
      state.updateCategories.isRejected = false;
    },
    [updateCategories.pending]: (state) => {
      state.updateCategories.isLoading = true;
      state.updateCategories.isRejected = false;
    },
    [updateCategories.fulfilled]: (state, { payload }) => {
      state.updateCategories.isLoading = false;
      state.updateCategories.data = payload?.data;
      const findIndex = state.categories.data.findIndex(
        (data) => data?.uuid === payload?.data?.uuid
      );
      if (findIndex >= 0) {
        state.categories.data[findIndex].categories_title =
          payload?.data?.categories_title;
      }
      state.updateCategories.isRejected = false;
    },
    [updateCategories.rejected]: (state) => {
      state.updateCategories.isLoading = false;
      state.updateCategories.isRejected = false;
    },
    [deleteCategories.pending]: (state) => {
      state.deleteCategories.isLoading = true;
      state.deleteCategories.isRejected = false;
    },
    [deleteCategories.fulfilled]: (state, action) => {
      state.deleteCategories.isLoading = false;
      if (action?.meta?.arg?.uuid) {
        state.categories.data = state.categories.data.filter(
          (category) => category?.uuid !== action?.meta?.arg?.uuid
        );
      }
      state.deleteCategories.isRejected = false;
    },
    [deleteCategories.rejected]: (state) => {
      state.deleteCategories.isLoading = false;
      state.deleteCategories.isRejected = false;
    },
  },
});
export const { toggleSidebar } = categoriesSlice.actions;
export default categoriesSlice.reducer;
