import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
import { tokenValue } from "./../../utils/api";

export const headerValue = () => {
  return {
    Accept: "application/json",
    Authorization: tokenValue,
  };
};

export const getCleanQueryString = (params) => {
  let { fromDate, ...query } = params;
  return Object.keys(query)
    .filter((key) => query[key] !== "")
    .map((keyName) => keyName + "=" + query[keyName])
    .join("&");
};

export const getChannel = createAsyncThunk(
  "dashboard/getChannel",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `channel/?list_type=all&${queryString}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getUserData = createAsyncThunk(
  "dashboard/getUserData",
  async (keyword, thunkAPI) => {
    try {
      let headers = headerValue();
      // let queryString = getCleanQueryString(keyword);
      const response = await axiosInterceptors.get(
        `explore/?keyword=${keyword}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getChannelById = createAsyncThunk(
  "dashboard/getChannelById",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `channel/?channel_uuid=${uuid}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateChannel = createAsyncThunk(
  "dashboard/updateChannel",
  async ({ reqBody, uuid }, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.put(
        `channel/${uuid}/`,
        reqBody,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addChannel = createAsyncThunk(
  "dashboard/addChannel",
  async (reqBody, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.post(
        `channel/`,
        reqBody,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteChannel = createAsyncThunk(
  "dashboard/deleteChannel",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.delete(
        `channel/${uuid}/`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const channelSlice = createSlice({
  name: "channel",
  initialState: {
    channel: { isLoading: false, data: [], isRejected: false },
    userData: { isLoading: false, data: [], isRejected: false },

    channelById: { isLoading: false, data: {}, isRejected: false },
    updateChannel: { isLoading: false, data: [], isRejected: false },
    deleteChannel: { isLoading: false, isRejected: false },
    channelDebounce: null,
    searchChannel: "",
    page: 1,
    pageSize: { value: 10 },
  },
  reducers: {
    clearChannel:(state,{payload})=> {
      state.pageSize = {value:10}
      state.page = 1
      state.searchChannel = ''
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setSearchChannel: (state, { payload }) => {
      state.page = 1;
      state.searchChannel = payload;
    },
    updateChannelDebounce: (state, { payload }) => {
      state.channelDebounce = payload;
    },
  },
  extraReducers: {
    [getChannel.pending]: (state) => {
      state.channel.isLoading = true;
      state.channel.isRejected = false;
    },
    [getChannel.fulfilled]: (state, { payload }) => {
      state.channel.isLoading = false;
      state.channel= { ...payload, data: payload?.data };
      state.channel.isRejected = false;
    },
    [getChannel.rejected]: (state) => {
      state.channel.isLoading = false;
      state.channel.isRejected = false;
    },
    [getUserData.pending]: (state) => {
      state.userData.isLoading = true;
      state.userData.isRejected = false;
    },
    [getUserData.fulfilled]: (state, { payload }) => {
      state.userData.isLoading = false;
      state.userData.data = payload;
      state.userData.isRejected = false;
    },
    [getUserData.rejected]: (state) => {
      state.userData.isLoading = false;
      state.userData.isRejected = false;
    },
    [getChannelById.pending]: (state) => {
      state.channelById.isLoading = true;
      state.channelById.isRejected = false;
    },
    [getChannelById.fulfilled]: (state, { payload }) => {
      state.channelById.isLoading = false;
      state.channelById.data = payload.data;
      state.channelById.isRejected = false;
    },
    [getChannelById.rejected]: (state) => {
      state.channelById.isLoading = false;
      state.channelById.isRejected = false;
    },
    [addChannel.pending]: (state) => {
      state.updateChannel.isLoading = true;
      state.updateChannel.isRejected = false;
    },
    [addChannel.fulfilled]: (state, { payload }) => {
      state.updateChannel.isLoading = false;
      state.updateChannel.isRejected = false;
    },
    [addChannel.rejected]: (state) => {
      state.updateChannel.isLoading = false;
      state.updateChannel.isRejected = false;
    },
    [updateChannel.pending]: (state) => {
      state.updateChannel.isLoading = true;
      state.updateChannel.isRejected = false;
    },
    [updateChannel.fulfilled]: (state, { payload }) => {
      state.updateChannel.isLoading = false;
      state.updateChannel.data = payload?.data;
      state.updateChannel.isRejected = false;
    },
    [updateChannel.rejected]: (state) => {
      state.updateChannel.isLoading = false;
      state.updateChannel.isRejected = false;
    },
    [deleteChannel.pending]: (state) => {
      state.deleteChannel.isLoading = true;
      state.deleteChannel.isRejected = false;
    },
    [deleteChannel.fulfilled]: (state, action) => {
      state.deleteChannel.isLoading = false;
      if (action?.meta?.arg?.uuid) {
        state.channel.data = state.channel.data.filter(
          (category) => category?.uuid !== action?.meta?.arg?.uuid
        );
      }
      state.deleteChannel.isRejected = false;
    },
    [deleteChannel.rejected]: (state) => {
      state.deleteChannel.isLoading = false;
      state.deleteChannel.isRejected = false;
    },
  },
});
export const {
  clearChannel,
  updateChannelDebounce,
  setSearchChannel,
  setPage,
  setPageSize,
} = channelSlice.actions;
export default channelSlice.reducer;
