import React from "react";
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { SideText } from "../Login";
export default function SuccessMessage() {
  const navigate = useNavigate();

  return (
    <div className="py-5 container-background min-h-screen  md:pl-[0px] lg:pl-[15px] lg:pr-[5px]">
      <img src={Logo} alt="logo" />
      <div className="flex items-center justify-start">
        <SideText />
        <div className="flex-1 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center w-auto text-black text-6xl leading-[98%] tracking-[0.06em] font-schmal">
              PASSWORD UPDATED SUCCESSFULLY
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="py-8 px-4 sm:rounded-lg sm:px-10">
              <div className="mt-1 text-center">
                <p className="font-medium">
                  Your password has been changed successfully
                </p>
              </div>
              <div className="bg-gradient-to-r mt-4 from-[#16E8FF] to-[#E900FF] p-0.5 rounded-[57px]">
                <button
                  onClick={() => navigate("/login")}
                  className="font-ibm text-base bg-white hover:bg-transparent font-semibold  py-2 px-4 hover:border-transparent hover:text-white rounded-[57px] w-full"
                >
                  Go to login page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
