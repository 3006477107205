import { DatePicker } from "antd";
import locale from "antd/locale/zh_CN";
import { useField } from "formik";
import moment from "moment";
import React from "react";

const CommonDatePicker = ({
  hideLabel,
  name,
  label,
  isDisabled,
  helpText,
  focus,
  className,
  icon,
  accept,
  inputType,
  placeHolder,
  textCapital,
  setFieldValue,
  values,
  ...props
}) => {
  const [inputProps, { error, touched }, { setValue }] = useField(name);
  const currentDate = moment(values);
  const handleDateChange = (date, dateString) => {
    setFieldValue(name, date);
  };
  const disabledDate = (current) => {
    return current && current.isAfter(currentDate, "day");
  };
  return (
    <>
      <div className="mb-6">
        {!hideLabel && (
          <div className="block text-sm font-medium text-gray-700 mb-1">
            {label}
          </div>
        )}
        <div className="relative">
          <DatePicker
            locale={locale}
            showToday={false}
            showTime={false}
            value={inputProps.value ?? ""}
            disabledDate={disabledDate}
            className={`font-ibm appearance-none block w-full px-6 py-2 border border-gray-300 rounded-[36px] shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 cursor-pointer focus:border-[#60A5FA] sm:text-sm  ${
              error && touched && "border-red-600"
            } ${className}`}
            onChange={handleDateChange}
            placeholder={placeHolder}
          />
        </div>
        {helpText && (
          <div className="mt-1 text-xs text-gray-700">{helpText}</div>
        )}
        {error && touched && (
          <div>
            <span className="text-xs text-red-500 mb-1">{error}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default CommonDatePicker;
