import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Input from "../Components/forms/Input";
import { loginUser } from "../Redux/auth/authSlice";
import ReportIssueIcon from "../assets/Icons/ReportIssueIcon";
import Logo from "../assets/images/logo.png";
import { emailRegExp } from "./signup/Signup";
const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format")
    .matches(emailRegExp, "Please enter a valid email"),

  password: Yup.string().required("Password is a required field"),
});

export const SideText = () => {
  return (
    <>
      <div className="flex-1">
        <h1 className="heading w-full font-schmal relative">
          <div className="absolute right-0 top-12 h-full border-r-4 border-[#0000001a]"></div>
          ONE APP.
          <br /> ALL THINGS ATHLETE.
        </h1>
      </div>
    </>
  );
};
export default function Login() {
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const dispatch = useDispatch();
  const { isFetching } = useSelector((state) => ({
    isFetching: state.auth.isFetching,
  }));
  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword1: !values.showPassword1,
    });
  };
  const loginObj = [
    {
      id: "email",
      name: "email",
      inputType: "email",
      label: "Email",
      autoComplete: "email",
      placeHolder: "Email",
    },
    {
      id: "password",
      name: "password",
      inputType: "password",
      label: "Password",
      autoComplete: "password",
      placeHolder: "Password",
      icon1: <EyeOutlined />,
      icon2: <EyeInvisibleOutlined />,
      iconsValue: values.showPassword1,
      handleClickShowPassword: handleClickShowPassword,
    },
  ];
  return (
    <div className="py-5 container-background min-h-screen  md:pl-[10px] lg:pl-[147px] lg:pr-[77px]">
      <div className="flex justify-between">
        <img src={Logo} alt="logo" />
        <div
          onClick={() => navigate("/report-issue")}
          className="mr-12 mt-2 cursor-pointer reportissue"
        >
          <ReportIssueIcon />
        </div>
      </div>
      <div className="flex items-center justify-start">
        <SideText />
        <div className="flex-1 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center font-extrabold text-black text-6xl leading-[98%] tracking-[0.06em] font-schmal">
              LOGIN
            </h2>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="py-8 px-4 sm:rounded-lg sm:px-10">
              <Formik
                validationSchema={schema}
                initialValues={{ email: "", password: "" }}
                onSubmit={(values) => {
                  const { email, password } = values;
                  dispatch(loginUser({ email, password }))
                    .unwrap()
                    .then((res) => {
                      if (res?.data && res?.data?.channel_uuid) {
                        navigate("/podcast");
                        window.location.reload(false);
                      } else if (res?.data && !!!res?.data?.channel_uuid) {
                        navigate("/channel");
                        window.location.reload(false);
                      }
                    });
                }}
              >
                {({ handleSubmit }) => (
                  <form
                    className="space-y-6"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <div className="mt-1">
                      {loginObj.map((item) => (
                        <>
                          <Input
                            {...item}
                            className="text-left px-6"
                            hideLabel={true}
                          />
                        </>
                      ))}
                    </div>

                    <div className="flex justify-end py-1">
                      <Link
                        style={{ color: "#777EFF" }}
                        className="text-sm leading-[22px]"
                        to="/forgot-password"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    <div className="bg-gradient-to-r from-[#16E8FF] to-[#E900FF] p-0.5 rounded-[57px]">
                      <button
                        type="submit"
                        className="font-ibm text-base bg-white hover:bg-transparent font-semibold  py-2 px-4 hover:border-transparent hover:text-white rounded-[57px] w-full"
                      >
                        {isFetching ? "Loading..." : "Sign in"}
                      </button>
                    </div>
                    <div className="flex justify-center fontstyle">
                      Don't Have An Account?
                      <Link
                        to="/signup"
                        style={{ color: "#777EFF", textDecoration: "none" }}
                        className="text-sm leading-[22px] underline ml-1"
                      >
                        Apply For One
                      </Link>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
