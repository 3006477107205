import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Input from "../../Components/forms/Input";
import { SendEmailOtp, VerifyEmailOtp } from "../../Redux/auth/authSlice";
import Logo from "../../assets/images/logo.png";
import { SideText } from "../Login";

const phoneRegExp = `/^[0-9\b]+$/`;

const schema = Yup.object().shape({
  code: Yup.string()
    .required("Access code is required")
    .matches(phoneRegExp, "Please enter 0-9 numbers only"),
});

export default function VerifyEmail(props) {
  const { setComponentValue } = props;
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.storeEmail);
  var hiddenEmail = "";
  for (var i = 0; i < email.length; i++) {
    if (i > 0 && i < email.indexOf("@") - 1) {
      hiddenEmail += "*";
    } else {
      hiddenEmail += email[i];
    }
  }
  const [timer, setTimer] = useState(60);
  const timeOutCallback = useCallback(
    () => setTimer((currentTimer) => currentTimer - 1),
    []
  );
  useEffect(() => {
    if (timer > 0) {
      const timeout = setTimeout(timeOutCallback, 1000);
      return () => clearTimeout(timeout);
    }
  }, [timer, timeOutCallback]);

  const handleClick = () => {
    dispatch(SendEmailOtp({ email_number: email }));
    setTimer(60);
  };
  return (
    <div className="py-5 container-background min-h-screen  md:pl-[0px] lg:pl-[15px] lg:pr-[5px]">
      <img src={Logo} alt="logo" />
      <div className="flex items-center justify-start">
        <SideText />
        <div className="flex-1 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center  text-black text-6xl leading-[98%] tracking-[0.06em] font-schmal">
              VERIFY YOUR EMAIL
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="py-8 px-4 sm:rounded-lg sm:px-10">
              <Formik
                validationSchema={schema}
                initialValues={{ code: "" }}
                onSubmit={(values) => {
                  const { code } = values;
                  dispatch(VerifyEmailOtp({ email, code }))
                    .unwrap()
                    .then((res) => {
                      if (res.status_code === 200) {
                        let result = {
                          token: res.data.token,
                        };
                        localStorage.setItem("user", JSON.stringify(result));
                        setComponentValue(3);
                      }
                    });
                }}
              >
                {({ handleSubmit }) => (
                  <form
                    className="space-y-6"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <div className="mt-1 text-center">
                      <p className="font-medium">
                        Please enter the access code that has been sent the
                        below email address
                      </p>
                      <p className="font-bold">Email: {hiddenEmail}</p>
                    </div>
                    <div className="mt-1">
                      <Input
                        id="code"
                        name="code"
                        type="text"
                        label="Code"
                        autoComplete="code"
                        placeHolder="000000"
                        className="text-center tracking-[1em]"
                        hideLabel={true}
                      />
                    </div>
                    <div className="text-center">
                      {timer > 0 ? (
                        <p className="text-[#777EFF]">{`Resend code in ${timer}`}</p>
                      ) : (
                        <p
                          className="text-[#777EFF] cursor-pointer"
                          onClick={handleClick}
                        >
                          Resend Code
                        </p>
                      )}
                    </div>
                    <div className="bg-gradient-to-r from-[#16E8FF] to-[#E900FF] p-0.5 rounded-[57px]">
                      <button
                        type="submit"
                        className="font-ibm text-base bg-white hover:bg-transparent font-semibold  py-2 px-4 hover:border-transparent hover:text-white rounded-[57px] w-full"
                      >
                        Confirm Code
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
