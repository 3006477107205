import { useField } from "formik";
import { Input } from "antd";
import React from "react";
const { TextArea } = Input;

const CommonTextArea = ({
  hideLabel,
  name,
  label,
  helpText,
  focus,
  className,
  icon,
  accept,
  inputType,
  maxLength,
  ...props
}) => {
  const [inputProps, { error, touched }, { setValue }] = useField(name);
  const [count, setCount] = React.useState(0);

  return (
    <>
      <div className="mb-6">
        {!hideLabel && (
          <div className="block text-sm font-medium text-gray-700 mb-1">
            {label}
          </div>
        )}
        <div className="relative">
          <TextArea
            autoSize={{
              minRows: 3,
              maxRows: 10,
            }}
            maxlength={maxLength ? maxLength : ""}
            style={{
              borderRadius: 25,
            }}
            type={inputType}
            className={`font-ibm appearance-none block w-full px-3 py-0.5 border border-gray-300 rounded-[36px] shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
              error && touched && "border-red-600 "
            } ${className}`}
            onChange={(event) => {
              setValue(event.target.value);
              setCount(event.target.value.length);
            }}
            {...inputProps}
            {...props}
          />
          {inputProps?.value?.length > maxLength ? (
            <span className="text-red-600 float-right">
              your exceeding limit
            </span>
          ) : (
            <span className="float-right text-gray-400">{`${inputProps?.value?.length}/${maxLength}`}</span>
          )}
          <div className="absolute top-4 right-3 text-gray-900">
            {icon && icon}
          </div>
        </div>
        {helpText && (
          <div className="mt-1 text-xs text-gray-700">{helpText}</div>
        )}
        {error && touched && (
          <div>
            <span className="text-xs text-red-500 mb-1">{error}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default CommonTextArea;
