// import { isDisabled } from "@testing-library/user-event/dist/utils";
import { useField } from "formik";

const Input = ({
  hideLabel,
  name,
  label,
  helpText,
  focus,
  className,
  icon1,
  icon2,
  accept,
  inputType,
  maxLength,
  textCapital,
  iconsValue,
  handleClickShowPassword,
  isDisabled,
  ...props
}) => {
  const [inputProps, { error, touched }, { setValue }] = useField(name);
  return (
    <>
      <div className="mb-6">
        {!hideLabel && (
          <div className="block text-sm font-medium text-gray-700 mb-1">
            {label}
          </div>
        )}
        <div className="relative">
          <input
            maxlength={maxLength ? maxLength : ""}
            accept={accept}
            type={iconsValue ? "text" : inputType}
            disabled={isDisabled}
            style={{
              color: `${
                inputProps.value && inputType === "date" ? "black" : ""
              }`,
            }}
            className={`font-ibm appearance-none block w-full px-3 py-2 border border-gray-300 rounded-[36px] shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
              textCapital ? textCapital : ""
            } ${error && touched && "border-red-600 "} ${className}`}
            onChange={(event) => setValue(event.target.value)}
            {...inputProps}
            {...props}
          />
          <div
            onClick={handleClickShowPassword}
            className="absolute top-1 right-3 cursor-pointer"
            style={{ color: "#9CA3AF" }}
          >
            {iconsValue ? icon1 : icon2}
          </div>
        </div>
        {helpText && (
          <div className="mt-1 text-xs text-gray-700">{helpText}</div>
        )}
        {error && touched && (
          <div>
            <span className="text-xs text-red-500 mb-1">{error}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default Input;
