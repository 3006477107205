import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";

export const ReportIssue = createAsyncThunk(
  "reportIssue/issue",
  async (reqBody, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`report-bug/`, reqBody);
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const AddMedia = createAsyncThunk(
  "reportIssue/issue",
  async (reqBody, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(
        `report-bug/add-media/`,
        reqBody
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const reportIssueSlice = createSlice({
  name: "reportIssue",
  initialState: {
    reportIssue: { isLoading: false, data: [], isRejected: false },
    addMedia: { isLoading: false, data: [], isRejected: false },
  },
  reducers: {},
  extraReducers: {
    [ReportIssue.pending]: (state) => {
      state.reportIssue.isLoading = true;
      state.reportIssue.isRejected = false;
    },
    [ReportIssue.fulfilled]: (state, { payload }) => {
      state.reportIssue.isLoading = false;
      state.reportIssue.isRejected = false;
    },
    [ReportIssue.rejected]: (state) => {
      state.reportIssue.isLoading = false;
      state.reportIssue.isRejected = true;
    },
    [AddMedia.pending]: (state) => {
      state.addMedia.isLoading = true;
      state.addMedia.isRejected = false;
    },
    [AddMedia.fulfilled]: (state, { payload }) => {
      state.addMedia.isLoading = false;
      state.addMedia.isRejected = false;
    },
    [AddMedia.rejected]: (state) => {
      state.addMedia.isLoading = false;
      state.addMedia.isRejected = true;
    },
  },
});

export default reportIssueSlice.reducer;
