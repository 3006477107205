import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../Components/forms/Button";
import CommonPhone from "../../Components/forms/CommonPhone";
import CommonTextArea from "../../Components/forms/CommonTextArea";
import Input from "../../Components/forms/Input";
import MultipleImageUpload from "../../Components/forms/MultipleImageUpload";
import SelectIssue from "../../Components/forms/SelectIssue";
import {
  AddMedia,
  ReportIssue,
} from "../../Redux/reportIssue/reportIssueSlice";
import Logo from "../../assets/images/logo.png";
import { charsOnly, emailRegExp } from "../signup/Signup";
import Spinner from "../../Components/ui/Spinner";
export const SideText = () => {
  return (
    <>
      <div className="flex-1">
        <h1 className="heading w-full font-schmal relative">
          <div className="absolute right-0 top-12 h-full border-r-4 border-[#0000001a]"></div>
          ONE APP.
          <br /> ALL THINGS ATHLETE.
        </h1>
      </div>
    </>
  );
};
export const acceptedFormatsImage = [".jpg", ".jpeg", ".png"];
const imageFileSchema = Yup.string()
  .required()
  .matches(/\.(jpg|jpeg|png)$/, "Must be a valid image file");

const schema = Yup.object().shape({
  user_issue: Yup.string().required("Issue is required"),
  description: Yup.string().required("Description is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required")
    .matches(emailRegExp, "Please enter a valid email"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9\b]+$/, "Please enter 0-9 numbers only")
    .min(10, "Please enter a valid 10-digit phone number."),
  first_name: Yup.string()
    .required("First name is required")
    .matches(charsOnly, "Please enter characters only"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(charsOnly, "Please enter characters only"),
  bug_image: Yup.array()
    .of(
      Yup.mixed().test(
        "FILE_FORMAT",
        `Invalid file type. Only images are allowed.`,
        (val) => {
          const fileName = val.name
            ? val.name.toLowerCase()
            : val.toLowerCase();
          return acceptedFormatsImage.some(
            (format) => fileName.endsWith(format) || fileName.includes(format),
          );
        },
      ),
    )
    .notRequired("At least one image file is required"),
});
export default function SendIssue(props) {
  const { setComponentValue, func } = props;
  const [imgURL, setImgURL] = useState(null);
  const [issuePicPath, setIssuePicPath] = useState(null);
  const [loader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reportIssueObj = [
    {
      name: "user_issue",
      inputType: "text",
      placeHolder: "Select Your issue",
      options: [
        {
          reason: "I didn't received any code",
        },
        {
          reason: "I can't login using my email address",
        },
        {
          reason: "Add your issue",
        },
      ],
    },
    {
      name: "description",
      inputType: "text",
      maxLength: "500",
      placeHolder: "Describe your issue",
    },
    {
      id: "first_name",
      name: "first_name",
      inputType: "text",
      label: "First Name",
      autoComplete: "first_name",
      placeHolder: "First Name",
      textCapital: "capitalize",
    },
    {
      id: "last_name",
      name: "last_name",
      inputType: "text",
      label: "Last Name",
      autoComplete: "last_name",
      placeHolder: "Last Name",
      textCapital: "capitalize",
    },
    {
      id: "email",
      name: "email",
      inputType: "email",
      label: "Email",
      autoComplete: "email",
      placeHolder: "Email Address",
    },
    {
      id: "phone",
      name: "phone",
      inputType: "text",
      maxLength: 10,
      autoComplete: "phone_number",
      placeHolder: "Phone Number",
    },
  ];
  const options = reportIssueObj[0].options;
  const [issueList, setIssueList] = useState(options);
  const handleData = function (data) {
    const x = data;
    func(x);
  };
  return (
    <>
      <div className="py-5 container-background min-h-screen  md:pl-[10px] lg:pl-[15px] lg:pr-[5px]">
        <div className="w-[90%] m-auto">
          <img src={Logo} alt="logo" />
          <div className="flex items-center justify-start">
            <SideText />
            <div className="flex-1 flex flex-col justify-center sm:px-6 lg:px-8 ">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-black text-6xl leading-[98%] tracking-[0.06em] font-schmal">
                  REPORT AN ISSUE
                </h2>
              </div>
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="py-8 px-4 sm:rounded-lg sm:px-10">
                  <Formik
                    validationSchema={schema}
                    initialValues={{
                      user_issue: "",
                      description: "",
                      first_name: "",
                      last_name: "",
                      email: "",
                      phone: "",
                      country_code: "+91",
                      bug_image: "",
                    }}
                    onSubmit={(values) => {
                      setIsLoader(true);
                      let dictObj = values;
                      let formData = {};
                      const imageFormData = new FormData();

                      if (typeof dictObj?.bug_image === "string") {
                        delete dictObj?.bug_image;
                      }

                      Object.keys(dictObj).forEach((key) => {
                        if (key === "bug_image") {
                          const files = dictObj[key];
                          for (let i = 0; i < files.length; i++) {
                            imageFormData.append(key, files[i].originFileObj);
                          }
                        } else {
                          formData[key] = dictObj[key];
                        }
                      });

                      if (dictObj?.bug_image?.length > 0) {
                        dispatch(AddMedia(imageFormData))
                          .unwrap()
                          .then((res) => {
                            dispatch(
                              ReportIssue({
                                ...formData,
                                media_uuid: res?.data?.uuid,
                              }),
                            )
                              .unwrap()
                              .then((res) => {
                                if (res?.status_code === 200) {
                                  setComponentValue(2);
                                  setIsLoader(false);
                                }
                              });
                          });
                      } else {
                        dispatch(
                          ReportIssue({
                            ...formData,
                          }),
                        )
                          .unwrap()
                          .then((res) => {
                            if (res?.status_code === 200) {
                              setComponentValue(2);
                              setIsLoader(false);
                            }
                          });
                      }
                    }}
                  >
                    {({ handleSubmit, values, setFieldValue }) => (
                      <form
                        className="space-y-6"
                        noValidate
                        onSubmit={handleSubmit}
                      >
                        <div className="mt-1">
                          {reportIssueObj.map((item, index) => (
                            <>
                              {item?.name === "user_issue" ? (
                                <SelectIssue
                                  // {...item}
                                  options={issueList}
                                  issueList={issueList}
                                  setIssueList={setIssueList}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  setComponentValue={setComponentValue}
                                  handleData={handleData}
                                />
                              ) : item.name === "description" ? (
                                <CommonTextArea
                                  {...item}
                                  className="px-6 rounded-none"
                                />
                              ) : item?.name === "phone" ? (
                                <CommonPhone
                                  {...item}
                                  key={index}
                                  codeName={"country_code"}
                                  codeValue={values.country_code}
                                  setFieldValue={setFieldValue}
                                  pCss="2px"
                                />
                              ) : (
                                <Input
                                  {...item}
                                  className="text-left px-6"
                                  hideLabel={true}
                                />
                              )}
                            </>
                          ))}

                          <div className="mt-4">
                            <MultipleImageUpload
                              imgURL={imgURL}
                              name={"bug_image"}
                              values={values}
                              setFieldValue={setFieldValue}
                              setImgURL={setImgURL}
                              setIssuePicPath={setIssuePicPath}
                              sideCSS={"ml-0"}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between gap-2">
                          {loader ? (
                            <Spinner />
                          ) : (
                            <>
                              <Button
                                className="border-red-500 w-[170px] text-black-500 hover:bg-red-700"
                                title="Cancel"
                                type="button"
                                btnType="filled"
                                onClick={() => navigate("/login")}
                              />
                              <Button
                                type="submit"
                                onClick={handleSubmit}
                                className={`w-[170px]`}
                                title={"Submit"}
                              />
                            </>
                          )}
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
