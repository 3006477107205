import { Field, useField } from "formik";
import React, { useState } from "react";
import Modal from "../ui/Modal";
import Button from "./Button";

export default function SelectIssue({
  options,
  values,
  setFieldValue,
  setComponentValue,
  setIssueList,
  issueList,
  handleData,
}) {
  const [field, { error, touched }] = useField("user_issue");
  const [issue, setIssue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [textBlack, setTextBlack] = useState(false);
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setFieldValue("user_issue", selectedValue);
    if (selectedValue === "Add your issue") {
      setShowModal(true);
    }
    setTextBlack(true);
  };

  const AddIssue = function () {
    if (issue) {
      setIssueList((prevList) => [{ reason: issue }, ...prevList]);
    }
    setShowModal(false);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShowModal(false);
    setFieldValue("user_issue", "");
  };
  return (
    <>
      <div className="">
        {showModal ? (
          <Modal
            show={!!showModal}
            showinput={!!showModal}
            handleClose={handleClose}
            handleData={handleData}
            setIssue={setIssue}
            AddIssue={AddIssue}
            title={`Please add your issue`}
            content={
              <div className="flex items-center gap-2 justify-end">
                <Button
                  onClick={(e) => {
                    handleData(AddIssue);
                    handleClose(e);
                  }}
                  className={`w-fit`}
                  title="Add Issue"
                />
                <Button
                  className="w-fit border-red-500 text-black-500 hover:bg-red-700"
                  title="Close"
                  type="button"
                  onClick={handleClose}
                  btnType="filled"
                />
              </div>
            }
          />
        ) : (
          ""
        )}
      </div>
      <div className="mb-5">
        <Field
          as="select"
          className={`select-container font-ibm  border ${
            textBlack ? "text-black" : "text-gray-400"
          } bg-[#fff] text-sm focus:ring-blue-500 rounded-[35px] focus:border-blue-500 block w-full    py-2 px-4 ${
            error && touched ? "border-red-500" : "border-gray-300"
          }`}
          onChange={handleSelectChange}
          value={field.value}
        >
          <option value="" disabled>
            Select Your Issue
          </option>
          {issueList.map((item, index) => (
            <option
              key={item.reason}
              value={item.reason}
              className="text-black"
            >
              {item.reason}
            </option>
          ))}
        </Field>
        {error && touched && (
          <span className="text-xs font-normal text-red-500">{error}</span>
        )}
      </div>
    </>
  );
}
