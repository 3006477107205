import "./App.css";
import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import DashboardLayout from "./Layouts/DashboardLayout";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { Toaster } from "react-hot-toast";
import { Navigate, Outlet } from "react-router-dom";
import Home from "./Pages/Home";
import { ListRouteElements } from "./Routes/route-elements";
import Spinner from "./Components/ui/Spinner";
import Forgot from "./Pages/forgotPassword";
import ReportIssue from "./Pages/reportIssue/index";

const Login = lazy(() => import("./Pages/Login"));
const ChannelAddEdit = lazy(() => import("./Pages/ChannelAddEdit"));
const SignUp = lazy(() => import("./Pages/signup/index"));
const App = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Provider store={store}>
        <Toaster
          containerStyle={{
            inset: "0px",
          }}
          toastOptions={{
            duration: 3000,
            className: "w-full",
            style: {
              zIndex: 999999,
            },
          }}
        ></Toaster>

        <DashboardLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<Forgot />} />
            <Route path="/report-issue" element={<ReportIssue />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/channel" element={<ChannelAddEdit />} />
            <Route exact path="/" element={<GuardedRoute />}>
              {ListRouteElements.map((item) => (
                <Route
                  key={item.path}
                  path={item.path}
                  element={item.element}
                />
              ))}
            </Route>
          </Routes>
        </DashboardLayout>
      </Provider>
    </Suspense>
  );
};

export default App;

const GuardedRoute = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  // If authorized, return an outlet that will render child elements
  return auth.channel_uuid ? <Outlet /> : <Navigate to="/channel" />;
};
