import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";
import { getCleanQueryString, headerValue } from "../channel/channelSlice";

export const getAllEpisode = createAsyncThunk(
  "dashboard/getAllEpisode",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `hypecast/episode-by-user/?${queryString}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getAllPodcast = createAsyncThunk(
  "dashboard/getAllPodcast",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `podcast-crud/?page=1&page_size=50`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getEpisode = createAsyncThunk(
  "dashboard/getEpisode",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();

      const response = await axiosInterceptors.get(
        `hypecast/episodes-by-podcast/${params?.podcastsId}/?page=${params.page}&page_size=${params.page_size}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getEpisodeRSS = createAsyncThunk(
  "dashboard/getEpisodeRSS",
  async (params, thunkAPI) => {
    try {
      let headers = headerValue();
      let queryString = getCleanQueryString(params);
      const response = await axiosInterceptors.get(
        `import-rss-feed/?${queryString}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const getEpisodeById = createAsyncThunk(
  "dashboard/getEpisodeById",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `hypecast/episode/?episode_uuid=${uuid}`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateEpisode = createAsyncThunk(
  "dashboard/updateEpisode",
  async ({ reqBody, uuid }, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.put(
        `hypecast/episode/${uuid}/`,
        reqBody,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addEpisode = createAsyncThunk(
  "dashboard/addEpisode",
  async ({ reqBody }, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.post(
        `hypecast/episode/`,
        reqBody,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const addRSS = createAsyncThunk(
  "dashboard/addRSS",
  async ({ reqBody }, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.post(
        `import-rss-feed/`,
        reqBody,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteEpisode = createAsyncThunk(
  "dashboard/deleteEpisode",
  async (uuid, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.delete(
        `hypecast/episode/${uuid}/`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const episodeSlice = createSlice({
  name: "episode",
  initialState: {
    episode: { isLoading: false, data: [], isRejected: false },
    episodeRSS: { isLoading: false, data: [], isRejected: false },
    podcast: { isLoading: false, data: [], isRejected: false },
    episodeById: { isLoading: false, data: {}, isRejected: false },
    updateEpisode: { isLoading: false, data: [], isRejected: false },
    deleteEpisode: { isLoading: false, isRejected: false },
    searchEpisode: "",
    episodeDebounce: null,
    pageSize: { value: 10 },
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
    clearEpisode: (state, { payload }) => {
      state.pageSize = { value: 10 };
      state.page = 1;
      state.searchEpisode = "";
    },
    setPageSize: (state, { payload }) => {
      state.pageSize = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setSearchEpisode: (state, { payload }) => {
      state.page = 1;
      state.searchEpisode = payload;
    },
    updateEpisodeDebounce: (state, { payload }) => {
      state.episodeDebounce = payload;
    },
  },
  extraReducers: {
    [getEpisode.pending]: (state) => {
      state.episode.isLoading = true;
      state.episode.isRejected = false;
    },
    [getEpisode.fulfilled]: (state, { payload }) => {
      state.episode.isLoading = false;
      state.episode.data = payload;
      state.episode.isRejected = false;
    },
    [getEpisode.rejected]: (state) => {
      state.episode.isLoading = false;
      state.episode.isRejected = false;
    },
    [getEpisodeRSS.pending]: (state) => {
      state.episodeRSS.isLoading = true;
      state.episodeRSS.isRejected = false;
    },
    [getEpisodeRSS.fulfilled]: (state, { payload }) => {
      state.episodeRSS.isLoading = false;
      state.episodeRSS.data = payload;
      state.episodeRSS.isRejected = false;
    },
    [getEpisodeRSS.rejected]: (state) => {
      state.episodeRSS.isLoading = false;
      state.episodeRSS.isRejected = false;
    },
    [getAllPodcast.pending]: (state) => {
      state.podcast.isLoading = true;
      state.podcast.isRejected = false;
    },
    [getAllPodcast.fulfilled]: (state, { payload }) => {
      state.podcast.isLoading = false;
      state.podcast.data = payload;
      state.podcast.isRejected = false;
    },
    [getAllPodcast.rejected]: (state) => {
      state.podcast.isLoading = false;
      state.podcast.isRejected = false;
    },
    [getEpisodeById.pending]: (state) => {
      state.episodeById.isLoading = true;
      state.episodeById.isRejected = false;
    },
    [getEpisodeById.fulfilled]: (state, { payload }) => {
      state.episodeById.isLoading = false;
      state.episodeById.data = payload.data;
      state.episodeById.isRejected = false;
    },
    [getEpisodeById.rejected]: (state) => {
      state.episodeById.isLoading = false;
      state.episodeById.isRejected = false;
    },
    [addEpisode.pending]: (state) => {
      state.updateEpisode.isLoading = true;
      state.updateEpisode.isRejected = false;
    },
    [addEpisode.fulfilled]: (state, { payload }) => {
      state.updateEpisode.isLoading = false;
      state.updateEpisode.isRejected = false;
    },
    [addEpisode.rejected]: (state) => {
      state.updateEpisode.isLoading = false;
      state.updateEpisode.isRejected = false;
    },
    [addRSS.pending]: (state) => {
      state.updateEpisode.isLoading = true;
      state.updateEpisode.isRejected = false;
    },
    [addRSS.fulfilled]: (state, { payload }) => {
      state.updateEpisode.isLoading = false;
      state.updateEpisode.isRejected = false;
    },
    [addRSS.rejected]: (state) => {
      state.updateEpisode.isLoading = false;
      state.updateEpisode.isRejected = false;
    },
    [updateEpisode.pending]: (state) => {
      state.updateEpisode.isLoading = true;
      state.updateEpisode.isRejected = false;
    },
    [updateEpisode.fulfilled]: (state, { payload }) => {
      state.updateEpisode.isLoading = false;
      state.updateEpisode.data = payload?.data;
      state.updateEpisode.isRejected = false;
    },
    [updateEpisode.rejected]: (state) => {
      state.updateEpisode.isLoading = false;
      state.updateEpisode.isRejected = false;
    },
    [deleteEpisode.pending]: (state) => {
      state.deleteEpisode.isLoading = true;
      state.deleteEpisode.isRejected = false;
    },
    [deleteEpisode.fulfilled]: (state, action) => {
      state.deleteEpisode.isLoading = false;
      if (action?.meta?.arg?.uuid) {
        state.episode.data = state.episode.data.filter(
          (episode) => episode?.uuid !== action?.meta?.arg?.uuid
        );
      }
      state.deleteEpisode.isRejected = false;
    },
    [deleteEpisode.rejected]: (state) => {
      state.deleteEpisode.isLoading = false;
      state.deleteEpisode.isRejected = false;
    },
    [getAllEpisode.pending]: (state) => {
      state.episode.isLoading = true;
      state.episode.isRejected = false;
    },
    [getAllEpisode.fulfilled]: (state, { payload }) => {
      state.episode.isLoading = false;
      state.episode.data = payload;
      state.episode.isRejected = false;
    },
    [getAllEpisode.rejected]: (state) => {
      state.episode.isLoading = false;
      state.episode.isRejected = false;
    },
  },
});
export const {
  toggleSidebar,
  updateEpisodeDebounce,
  setSearchEpisode,
  setPage,
  setPageSize,
  clearEpisode,
} = episodeSlice.actions;
export default episodeSlice.reducer;
