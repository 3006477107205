import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInterceptors } from "../../utils/api";

export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`pro-athlete-login/`, {
        email,
        password,
      });
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const SendEmailOtp = createAsyncThunk(
  "auth/otp",
  async ({ email_number }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`send-reset-otp-email/`, {
        email: email_number,
      });
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const ForgetPasswordOtp = createAsyncThunk(
  "auth/forget_password",
  async ({ email_number }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(
        `content-creator-forgot-password/`,
        {
          email: email_number,
        }
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const VerifyEmailOtp = createAsyncThunk(
  "auth/verify",
  async ({ email, code }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`verify-reset-otp-email/`, {
        email_otp: code,
        email: email,
      });
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const ChangePassword = createAsyncThunk(
  "auth/reset",
  async (reqBody, thunkAPI) => {
    try {
      const response = await axiosInterceptors.put(`change-password/`, reqBody);
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const SignUp = createAsyncThunk(
  "auth/signup",
  async (reqBody, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(
        `pro-athlete-sign-up/`,
        reqBody
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const VerifySignUpEmail = createAsyncThunk(
  "auth/verifyemail",
  async ({ user_uuid, code }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`email-verification/`, {
        email_otp: code,
        user_uuid: user_uuid,
      });
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const SendPhoneCode = createAsyncThunk(
  "auth/sendphonecode",
  async ({ user_uuid }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`resend-phone-otp/`, {
        user_uuid: user_uuid,
      });
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const VerifySignUpPhone = createAsyncThunk(
  "auth/verifyphone",
  async ({ user_uuid, code }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`verify-phone/`, {
        phone_otp: code,
        user_uuid: user_uuid,
      });
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const authSlice = createSlice({
  name: "auth",
  initialState: {
    email: "",
    password: "",
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
    userData: JSON.parse(localStorage.getItem("user-data")),
    redirectLogin: {
      isLoading: false,
      data: null,
      isRejected: false,
    },
    emailOtp: { isLoading: false, data: [], isRejected: false },
    storeEmail: { email: "" },
    storePhone: { phone: "" },
    verifyEmail: { isLoading: false, data: [], isRejected: false },
    forgetPasswordOtp: { isLoading: false, data: [], isRejected: false },
    reset: { isLoading: false, data: [], isRejected: false },
    signup: { isLoading: false, data: [], isRejected: false },
    verifySignupEmail: { isLoading: false, data: [], isRejected: false },
    sendPhoneOtp: { isLoading: false, data: [], isRejected: false },
    verifySignupPhone: { isLoading: false, data: [], isRejected: false },
  },
  reducers: {
    clearRedirectLoginData: (state) => {
      state.redirectLogin = {
        isLoading: false,
        data: null,
        isRejected: false,
      };
    },
    storeEmail: (state, { payload }) => {
      state.storeEmail = payload;
    },
    storePhone: (state, { payload }) => {
      state.storePhone = payload;
    },
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      return state;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      if (!!payload?.data) {
        localStorage.setItem("user", JSON.stringify(payload.data));
      } else {
        state.isFetching = false;
      }
    },
    [loginUser.rejected]: (state) => {
      state.isError = true;
      state.isFetching = false;
    },
    [SendEmailOtp.pending]: (state) => {
      state.emailOtp.isLoading = true;
      state.emailOtp.isRejected = false;
    },
    [SendEmailOtp.fulfilled]: (state, { payload }) => {
      state.emailOtp.isLoading = false;
      state.emailOtp.isRejected = false;
    },
    [SendEmailOtp.rejected]: (state) => {
      state.emailOtp.isLoading = false;
      state.emailOtp.isRejected = true;
    },
    [VerifyEmailOtp.pending]: (state) => {
      state.verifyEmail.isLoading = true;
      state.verifyEmail.isRejected = false;
    },
    [VerifyEmailOtp.fulfilled]: (state, { payload }) => {
      state.verifyEmail.isLoading = false;
      state.verifyEmail.isRejected = false;
    },
    [VerifyEmailOtp.rejected]: (state) => {
      state.verifyEmail.isLoading = false;
      state.verifyEmail.isRejected = true;
    },
    [ChangePassword.pending]: (state) => {
      state.reset.isLoading = true;
      state.reset.isRejected = false;
    },
    [ChangePassword.fulfilled]: (state, { payload }) => {
      state.reset.isLoading = false;
      state.reset.isRejected = false;
    },
    [ChangePassword.rejected]: (state) => {
      state.reset.isLoading = false;
      state.reset.isRejected = true;
    },
    [SignUp.pending]: (state) => {
      state.signup.isLoading = true;
      state.signup.isRejected = false;
    },
    [SignUp.fulfilled]: (state, { payload }) => {
      state.signup.isLoading = false;
      state.signup.isRejected = false;
    },
    [SignUp.rejected]: (state) => {
      state.signup.isLoading = false;
      state.signup.isRejected = true;
    },
    [VerifySignUpEmail.pending]: (state) => {
      state.verifySignupEmail.isLoading = true;
      state.verifySignupEmail.isRejected = false;
    },
    [VerifySignUpEmail.fulfilled]: (state, { payload }) => {
      state.verifySignupEmail.isLoading = false;
      state.verifySignupEmail.isRejected = false;
    },
    [VerifySignUpEmail.rejected]: (state) => {
      state.verifySignupEmail.isLoading = false;
      state.verifySignupEmail.isRejected = true;
    },
    [SendPhoneCode.pending]: (state) => {
      state.sendPhoneOtp.isLoading = true;
      state.sendPhoneOtp.isRejected = false;
    },
    [SendPhoneCode.fulfilled]: (state, { payload }) => {
      state.sendPhoneOtp.isLoading = false;
      state.sendPhoneOtp.isRejected = false;
    },
    [SendPhoneCode.rejected]: (state) => {
      state.sendPhoneOtp.isLoading = false;
      state.sendPhoneOtp.isRejected = true;
    },
    [VerifySignUpPhone.pending]: (state) => {
      state.verifySignupPhone.isLoading = true;
      state.verifySignupPhone.isRejected = false;
    },
    [VerifySignUpPhone.fulfilled]: (state, { payload }) => {
      state.verifySignupPhone.isLoading = false;
      state.verifySignupPhone.isRejected = false;
    },
    [VerifySignUpPhone.rejected]: (state) => {
      state.verifySignupPhone.isLoading = false;
      state.verifySignupPhone.isRejected = true;
    },
    [ForgetPasswordOtp.pending]: (state) => {
      state.forgetPasswordOtp.isLoading = true;
      state.forgetPasswordOtp.isRejected = false;
    },
    [ForgetPasswordOtp.fulfilled]: (state, { payload }) => {
      state.forgetPasswordOtp.isLoading = false;
      state.forgetPasswordOtp.isRejected = false;
    },
    [ForgetPasswordOtp.rejected]: (state) => {
      state.forgetPasswordOtp.isLoading = false;
      state.forgetPasswordOtp.isRejected = true;
    },
  },
});

export const { clearState, clearRedirectLoginData, storeEmail, storePhone } =
  authSlice.actions;
