import React from "react";
import { lazy } from "react";

const NoMatch = lazy(() => import("./../Pages/NoMatch"));
const Podcast = lazy(() => import("./../Pages/Podcast"));
const Episode = lazy(() => import("./../Pages/Episode"));
const PodcastAddEdit = lazy(() => import("./../Pages/PodcastAddEdit"));
const UserProfile = lazy(() => import("./../Pages/userProfile/UserProfile"));
const EpisodeAddEdit = lazy(() => import("./../Pages/EpisodeAddEdit"));
const EpisodeRSSAdd = lazy(() => import("./../Pages/EpisodeRSSAdd"));

/* add your route here
 * ex.
 * { path: RoutePath.home, element: <Home /> },
 */

export const ListRouteElements = [
  { path: "/podcast", element: <Podcast /> },
  { path: "/podcast/add", element: <PodcastAddEdit /> },
  { path: "/podcast/edit/:id", element: <PodcastAddEdit /> },
  { path: "/episode", element: <Episode /> },
  { path: "/episode/add", element: <EpisodeAddEdit /> },
  { path: "/episode/rss/add", element: <EpisodeRSSAdd /> },
  { path: "/episode/edit/:id", element: <EpisodeAddEdit /> },
  { path: "/user-profile", element: <UserProfile /> },
  { path: "*", element: <NoMatch /> },
];
