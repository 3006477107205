import React, { useState } from "react";
import SendIssue from "./ReportIssue";
import SuccessMessage from "./SuccessMessage";

export default function ReportIssue() {
  const [componentValue, setComponentValue] = useState(1);
  const func = function (data) {
    const y = data;
    y();
  };
  let data = {
    setComponentValue: setComponentValue,
    func: func,
  };
  const renderViewBaseOnValue = (componentValue) => {
    let component;
    switch (componentValue) {
      case 1:
        component = <SendIssue {...data} />;
        break;
      case 2:
        component = <SuccessMessage {...data} />;
        break;
      default:
    }
    return component;
  };

  // main view
  return <>{renderViewBaseOnValue(componentValue)}</>;
}
