import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Components/forms/Button";
import Input from "../Components/forms/Input";
import Modal from "../Components/ui/Modal";
import {
  createEmailMarketing,
  deleteUserRecord,
  getEmailMarketing,
  getStatesData,
  updateEmailMarketing,
} from "../Redux/dashboard/dashboardSlice";
import * as Yup from "yup";

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
});
export default function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmailMarketing());
    dispatch(getStatesData());
    return () => {
      setShow(false);
    };
  }, [dispatch]);
  const { usersData, updateUser } = useSelector((state) => ({
    usersData: state.dashboard.usersData,
    updateUser: state.dashboard.updateUser,
  }));
  const [show, setShow] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  return (
    <div className="p-4 md:p-6">
      {deleteUser ? (
        <Modal
          show={!!deleteUser}
          title={`Are you sure you want to delete user:\n${deleteUser?.email}?`}
          content={
            <div className="flex items-center gap-2 justify-end">
              <Button
                onClick={() =>
                  dispatch(deleteUserRecord({ uuid: deleteUser?.uuid }))
                }
                className={`w-fit ${
                  updateUser?.isLoading ? "opacity-50" : "opacity-100"
                }`}
                disabled={updateUser?.isLoading}
                title="Delete"
              />
              <Button
                className="w-fit border-red-500 text-black-500 hover:bg-red-700"
                title="Close"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setDeleteUser(false);
                }}
                btnType="filled"
              />
            </div>
          }
        />
      ) : (
        ""
      )}
      {show && (
        <Modal
          show={show}
          title={isEdit ? "Edit User" : "Add User"}
          setShow={(e) => setShow(e)}
          content={
            <Formik
              initialValues={{
                email: isEdit ? isEdit?.email : "",
                // category: { name: "select" },
              }}
              validationSchema={schema}
              onSubmit={(values) => {
                if (isEdit) {
                  dispatch(
                    updateEmailMarketing({
                      email: values?.email,
                      uuid: isEdit?.uuid,
                      // category: values?.category?.uuid,
                    })
                  )
                    .unwrap()
                    .then((res) => {
                      if (res) {
                        setShow(false);
                      }
                    });
                } else {
                  dispatch(
                    createEmailMarketing({
                      email: values?.email,
                      // category: values?.category?.uuid,
                    })
                  )
                    .unwrap()
                    .then((res) => {
                      if (res) {
                        setShow(false);
                      }
                    });
                }
              }}
            >
              {({ handleSubmit, values, setFieldValue }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Input name="email" type="email" label="Email" />
                  {/* <Select
                    selectedValue={values?.category}
                    heightOfDropdown="max-h-40"
                    setSelectedValue={(value) => {
                      setFieldValue("category", value);
                    }}
                    options={emailCategory?.data?.map((data) => {
                      return { name: data?.category_name, uuid: data?.uuid };
                    })}
                    name="category"
                  /> */}
                  <div className="flex items-center gap-2 justify-end">
                    <Button
                      type="submit"
                      className={`w-fit ${
                        updateUser?.isLoading ? "opacity-50" : "opacity-100"
                      }`}
                      disabled={updateUser?.isLoading}
                      title={isEdit ? "Update" : "Add"}
                    />
                    <Button
                      className="w-fit border-red-500 text-black-500 hover:bg-red-700"
                      title="Close"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setShow(false);
                      }}
                      btnType="filled"
                    />
                  </div>
                </form>
              )}
            </Formik>
          }
        />
      )}
      {usersData?.isLoading ? (
        "Loading..."
      ) : (
        <div className="">
          <div className="w-44 my-2">
            <Button
              onClick={() => {
                setShow(true);
                setIsEdit(false);
              }}
              title="Add User"
              className="w-full max-w-xs"
            />
          </div>
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        uuid
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {usersData?.data?.map((person) => (
                      <tr key={person.email}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {person?.uuid}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {person?.email}
                        </td>
                        <td className="flex items-center justify-start gap-4 px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() => {
                              setShow(true);
                              setIsEdit({
                                email: person?.email,
                                uuid: person?.uuid,
                              });
                            }}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => {
                              setDeleteUser({
                                email: person?.email,
                                uuid: person?.uuid,
                              });
                            }}
                            className="text-red-500 hover:text-red-900"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
