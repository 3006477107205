import { Input, Select } from "antd";
import { useField } from "formik";
import React, { useState } from "react";

const { Option } = Select;

const countries = ["+1", "+91"];

export default function CommonPhone({
  hideLabel,
  name,
  label,
  isDisabled,
  helpText,
  focus,
  className,
  icon1,
  icon2,
  accept,
  inputType,
  placeholder,
  textCapital,
  onClicked,
  iconsValue,
  codeValue,
  setFieldValue,
  codeName,
  pCss,
  ...props
}) {
  const [inputProps, { error, touched }, { setValue }] = useField(name);
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const countries = ["+1", "+91"];
  const getBorderColor = () => {
    if (focused || hovered) {
      return "1px solid #60A5FA";
    }
    if (error && touched) {
      return "1px solid #E53935";
    }
  };
  return (
    <div className="mb-6">
      {!hideLabel && (
        <div className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </div>
      )}
      <>
        <Input.Group
          compact
          onBlur={(e) => {
            if (e.currentTarget !== e.target) {
              setFocused(false);
            }
          }}
        >
          <Select
            style={{
              width: "25%",
              textAlign: "left",
              paddingBottom: pCss,
              paddingTop: pCss,
              border: getBorderColor(),
              borderRight: "0px",
            }}
            className={`font-ibm appearance-none block border border-gray-300 rounded-[36px] shadow-sm placeholder-gray-400  sm:text-sm borderedSelect `}
            value={codeValue}
            onChange={(value) => setFieldValue(codeName, value)}
            bordered={false}
            onFocus={() => setFocused(true)}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
          >
            {countries.map((country) => (
              <Option key={country} value={country}>
                {country}
              </Option>
            ))}
          </Select>
          <Input
            style={{
              borderTopRightRadius: 50,
              borderBottomRightRadius: 50,
              borderLeft: "none",
              outline: "none",
              padding: "8px",
              width: "75%",
              borderRight: getBorderColor(),
              borderTop: getBorderColor(),
              borderBottom: getBorderColor(),
            }}
            accept={accept}
            type={iconsValue ? "text" : inputType}
            disabled={isDisabled}
            placeholder={placeholder}
            className={`font-ibm appearance-none block p-4 border border-gray-300 rounded-[36px] shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-[#60A5FA] sm:text-sm ${
              textCapital ? textCapital : ""
            } ${error && touched && "border-red-600 "} ${className}`}
            onChange={(event) => setValue(event.target.value)}
            onFocus={() => setFocused(true)}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            {...inputProps}
            {...props}
          />
        </Input.Group>
      </>
      <div
        onClick={onClicked}
        className="absolute top-1 right-3 cursor-pointer"
        style={{ color: "#9CA3AF" }}
      >
        {iconsValue ? icon1 : icon2}
      </div>
      {helpText && <div className="mt-1 text-xs text-gray-700">{helpText}</div>}
      {error && touched && (
        <div>
          <span className="text-xs text-red-500 mb-1">{error}</span>
        </div>
      )}
    </div>
  );
}
