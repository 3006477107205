import { Formik } from "formik";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Input from "../../Components/forms/Input";
import {
  SendEmailOtp,
  SignUp,
  VerifySignUpEmail,
} from "../../Redux/auth/authSlice";
import Logo from "../../assets/images/logo.png";
import { phoneRegExp } from "./Signup";
import { ThemeContext } from ".";

const schema = Yup.object().shape({
  code: Yup.string()
    .required("Access code is required")
    .min(6, "Access code is required"),
});

export default function VerifyEmail(props) {
  const { setComponentValue } = props;
  const [info, setinfo] = useContext(ThemeContext);
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.storeEmail);
  const [timer, setTimer] = useState(60);
  const timeOutCallback = useCallback(
    () => setTimer((currentTimer) => currentTimer - 1),
    []
  );
  useEffect(() => {
    if (timer > 0) {
      const timeout = setTimeout(timeOutCallback, 1000);
      return () => clearTimeout(timeout);
    }
  }, [timer, timeOutCallback]);
  const handleClick = () => {
    dispatch(SignUp(info));
    setTimer(60);
  };
  return (
    <div className="py-5 container-background min-h-screen  sm:pl-[75px] md:pl-[147px] md:pr-[77px]">
      <img src={Logo} alt="logo" />
      <div className="flex items-center justify-start">
        <div className="flex-1">
          <h1 className="heading w-full font-schmal relative">
            <div className="absolute right-0 top-12 h-full border-r-4 border-[#0000001a]"></div>
            THE PREMIER NETWORK <br />
            FOR YOUTH ATHLETES
          </h1>
        </div>
        <div className="flex-1 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center  text-black text-6xl leading-[98%] tracking-[0.06em] font-schmal">
              VERIFY YOUR EMAIL
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="py-8 px-4 sm:rounded-lg sm:px-10">
              <Formik
                validationSchema={schema}
                initialValues={{ code: "", text: "" }}
                onSubmit={(values) => {
                  const { text } = values;
                  const value = JSON.parse(localStorage.getItem("user"));
                  const { user_uuid } = value;
                  dispatch(VerifySignUpEmail({ user_uuid, code: text }))
                    .unwrap()
                    .then((res) => {
                      if (res.status_code === 200) {
                        setComponentValue(3);
                      }
                    });
                }}
              >
                {({ handleSubmit, values, setFieldValue }) => (
                  <form
                    className="space-y-6"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <div className="mt-1 text-center">
                      <p className="font-medium">
                        Please enter the access code that has been sent the
                        below email address
                      </p>
                      <p className="font-bold">Email:{email}</p>
                    </div>
                    <div className="mt-1">
                      <Input
                        id="code"
                        name="code"
                        type="text"
                        label="Code"
                        autoComplete="code"
                        value={values.text}
                        placeHolder="000000"
                        maxLength={6}
                        className="text-center tracking-[1em]"
                        hideLabel={true}
                        onInput={(event) => {
                          (/^\d+$/.test(event.target.value) ||
                            event.target.value === "") &&
                            setFieldValue("text", event.target.value);
                        }}
                      />
                    </div>
                    <div className="bg-gradient-to-r from-[#16E8FF] to-[#E900FF] p-0.5 rounded-[57px]">
                      <button
                        type="submit"
                        className="font-ibm text-base bg-white hover:bg-transparent font-semibold  py-2 px-4 hover:border-transparent hover:text-white rounded-[57px] w-full"
                      >
                        {/* {isFetching ? "Loading..." : "Sign in"} */}
                        Confirm Code
                      </button>
                    </div>
                    <div className="text-center">
                      {timer > 0 ? (
                        <p className="text-[#777EFF]">{`Resend code in ${timer}`}</p>
                      ) : (
                        <p
                          className="text-[#777EFF] cursor-pointer"
                          onClick={handleClick}
                        >
                          Resend Code
                        </p>
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
