import React from "react";
import Logo from "../../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { SendPhoneCode } from "../../Redux/auth/authSlice";

export default function EmailVerified(props) {
  const { setComponentValue } = props;
  const dispatch = useDispatch();
  const handleSendPhoneCode = () => {
    const value = JSON.parse(localStorage.getItem("user"));
    const { user_uuid } = value;
    dispatch(SendPhoneCode({ user_uuid }))
      .unwrap()
      .then((res) => {
        if (res.status_code === 200) {
          setComponentValue(4);
        }
      });
  };
  return (
    <div className="py-5 container-background min-h-screen  sm:pl-[75px] md:pl-[147px] md:pr-[77px]">
      <img src={Logo} alt="logo" />
      <div className="flex items-center justify-start">
        <div className="flex-1">
          <h1 className="heading w-full font-schmal relative">
            <div className="absolute right-0 top-12 h-full border-r-4 border-[#0000001a]"></div>
            THE PREMIER NETWORK <br />
            FOR YOUTH ATHLETES
          </h1>
        </div>
        <div className="flex-1 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center w-auto text-black text-6xl leading-[98%] tracking-[0.06em] font-schmal">
              EMAIL VERIFIED
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="py-8 px-4 sm:rounded-lg sm:px-10">
              <div className="mt-1 text-center">
                <p className="font-medium">
                  Please provide more information in the next steps.
                </p>
              </div>
              <div className="bg-gradient-to-r mt-4 from-[#16E8FF] to-[#E900FF] p-0.5 rounded-[57px]">
                <button
                  onClick={handleSendPhoneCode}
                  type="submit"
                  className="font-ibm text-base bg-white hover:bg-transparent font-semibold  py-2 px-4 hover:border-transparent hover:text-white rounded-[57px] w-full"
                >
                  Continue to next steps
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
