import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { useState } from "react";
import { useField } from "formik";
import ImageCrop from "./image-crop/ImageCrop";
import { useEffect } from "react";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const MultipleImageUpload = ({
  setPodcastPicPath,
  label,
  imgURL,
  helpText,
  name,
  setFieldValue,
  setImgURL,
  sideCSS,
  isDisabled,
  values,
  isEdit,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [openCrop, setOpenCrop] = useState(false);
  const [newImg, setNewImg] = useState(null);

  if (imgURL && !!!fileList[0]?.url) {
    setFileList([
      {
        url: imgURL,
      },
    ]);
  } else if (newImg && !!!fileList[0]?.url) {
    setFileList([
      {
        url: newImg,
      },
    ]);
  }
  const handleCancel = () => {
    setPreviewOpen(false);
    setOpenCrop(false);
  };
  useEffect(() => {
    if (typeof values[name] === "object") {
      handlePreview(values[name]);
    }
  }, [values[name], newImg]);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      if (file.originFileObj && typeof values[name] === "string") {
        file.preview = await getBase64(file.originFileObj);
      } else {
        file.preview = await getBase64(values[name]);
      }
    }
    setPreviewImage(file?.url || file?.preview);
    setNewImg(file?.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file?.name || file?.url.substring(file.url.lastIndexOf("/") + 1),
    );
  };

  const handleChanges = ({ fileList: newFileList }) => {
    setFieldValue(name, newFileList);
    setImgURL(null);
    setFileList(newFileList);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const [inputProps, { error, touched }, { setValue }] = useField(name);

  const getErrorMessage = () => {
    if (Array.isArray(error) && error.length > 0) {
      const uniqueErrors = [
        ...new Set(error.filter((err) => typeof err === "string")),
      ];
      return uniqueErrors.join(" "); // Concatenate error messages
    } else {
      return error;
    }
  };

  return (
    <div className={sideCSS ? sideCSS : "mb-6"}>
      <div className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </div>
      <div className="relative">
        <Upload
          accept="image/*"
          value={name}
          action=""
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          // onChange={handleChange}
          onChange={handleChanges}
          beforeUpload={true}
          disabled={isDisabled}
          // showRemoveIcon={true}
          removeIcon={null}
          multiple
        >
          {uploadButton}
        </Upload>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt=""
            style={{
              width: "100%",
            }}
            src={
              typeof values[name] === "object"
                ? values[name]?.preview
                : previewImage
            }
          />
        </Modal>

        {helpText && (
          <div className="mt-1 text-xs text-gray-700">{helpText}</div>
        )}
        {(error && touched) || (error && !touched && isEdit) ? (
          <div>
            <span className="text-xs text-red-500 mb-1">
              {getErrorMessage()}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default MultipleImageUpload;
