import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInterceptors } from '../../utils/api';
import { headerValue } from '../podcast/podcastSlice';

export const getSponsor = createAsyncThunk(
  'sponsored-content/getSponsor',
  async (_, thunkAPI) => {
    try {
      let headers = headerValue();
      const response = await axiosInterceptors.get(
        `sponsored-content/`,
        headers
      );
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const updateSponsor = createAsyncThunk(
  'sponsored-content/updateSponsor',
  async ({ sponsor_title, uuid }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.put(
        `sponsored-content/${uuid}/`,
        {
          sponsor_title,
        }
      );

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const addSponsor = createAsyncThunk(
  'sponsored-content/addSponsor',
  async ({ sponsor_name }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.post(`sponsored-content/`, {
        sponsor_name,
      });

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const deleteSponsor = createAsyncThunk(
  'sponsored-content/deleteSponsor',
  async ({ uuid }, thunkAPI) => {
    try {
      const response = await axiosInterceptors.delete(
        `sponsored-content/${uuid}/`
      );

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
export const sponsorSlice = createSlice({
  name: 'sponsor',
  initialState: {
    sponsor: { isLoading: false, data: [], isRejected: false },
    updateSponsor: { isLoading: false, data: [], isRejected: false },
    deleteSponsor: { isLoading: false, isRejected: false },
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarExpanded = !state.sidebarExpanded;
    },
  },
  extraReducers: {
    [getSponsor.pending]: (state) => {
      state.sponsor.isLoading = true;
      state.sponsor.isRejected = false;
    },
    [getSponsor.fulfilled]: (state, { payload }) => {
      state.sponsor.isLoading = false;
      state.sponsor.data = payload?.data;
      state.sponsor.isRejected = false;
    },
    [getSponsor.rejected]: (state) => {
      state.sponsor.isLoading = false;
      state.sponsor.isRejected = false;
    },
    [addSponsor.pending]: (state) => {
      state.updateSponsor.isLoading = true;
      state.updateSponsor.isRejected = false;
    },
    [addSponsor.fulfilled]: (state, { payload }) => {
      state.updateSponsor.isLoading = false;
      state.sponsor.data = [payload.data, ...state.sponsor.data];
      state.updateSponsor.isRejected = false;
    },
    [addSponsor.rejected]: (state) => {
      state.updateSponsor.isLoading = false;
      state.updateSponsor.isRejected = false;
    },
    [updateSponsor.pending]: (state) => {
      state.updateSponsor.isLoading = true;
      state.updateSponsor.isRejected = false;
    },
    [updateSponsor.fulfilled]: (state, { payload }) => {
      state.updateSponsor.isLoading = false;
      state.updateSponsor.data = payload?.data;
      const findIndex = state.sponsor.data.findIndex(
        (data) => data?.uuid === payload?.data?.uuid
      );
      if (findIndex >= 0) {
        state.sponsor.data[findIndex].sponsor_title =
          payload?.data?.sponsor_title;
      }
      state.updateSponsor.isRejected = false;
    },
    [updateSponsor.rejected]: (state) => {
      state.updateSponsor.isLoading = false;
      state.updateSponsor.isRejected = false;
    },
    [deleteSponsor.pending]: (state) => {
      state.deleteSponsor.isLoading = true;
      state.deleteSponsor.isRejected = false;
    },
    [deleteSponsor.fulfilled]: (state, action) => {
      state.deleteSponsor.isLoading = false;
      if (action?.meta?.arg?.uuid) {
        state.sponsor.data = state.sponsor.data.filter(
          (sponsor) => sponsor?.uuid !== action?.meta?.arg?.uuid
        );
      }
      state.deleteSponsor.isRejected = false;
    },
    [deleteSponsor.rejected]: (state) => {
      state.deleteSponsor.isLoading = false;
      state.deleteSponsor.isRejected = false;
    },
  },
});
export const { toggleSidebar } = sponsorSlice.actions;
export default sponsorSlice.reducer;
