import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../Components/forms/Button";
import Input from "../../Components/forms/Input";
import { ForgetPasswordOtp, storeEmail } from "../../Redux/auth/authSlice";
import Logo from "../../assets/images/logo.png";
import { SideText } from "../Login";
const schema = Yup.object().shape({
  email_number: Yup.string()
    .required("Email is a required field")
    .email("Invalid email"),
  // .matches(/^(?:\d{10}|\w+@\w+\.\w{2,3})$/, {
  //   message: "Please enter valid Email or Phone number",
  // }),
});

export default function ForgotPassword(props) {
  const { setComponentValue } = props;
  const dispatch = useDispatch();
  localStorage.clear();
  const router = useNavigate();

  return (
    <div className="py-5 container-background min-h-screen sm:pl-[0px] lg:pl-[15px] lg:pr-[5px]">
      <div className="w-[90%] m-auto">
        <img src={Logo} alt="logo" />
        <div className="flex items-center mt-20 justify-start pl-1">
          <SideText />
          <div className="flex-1 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <h2 className="mt-6 text-center text-black text-6xl leading-[98%] tracking-[0.06em] font-schmal">
                FORGOT PASSWORD
              </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="py-8 px-4 sm:rounded-lg sm:px-10">
                <Formik
                  validationSchema={schema}
                  initialValues={{ email_number: "" }}
                  onSubmit={(values) => {
                    const { email_number } = values;
                    if (email_number.includes("@")) {
                      dispatch(storeEmail(email_number));
                      dispatch(ForgetPasswordOtp({ email_number }))
                        .unwrap()
                        .then((res) => {
                          if (res.status_code === 200) {
                            setComponentValue(2);
                          }
                        })
                        .catch((err) => {});
                    } else {
                      // dispatch(SendNumberOtp({ email_number }))
                      //   .unwrap()
                      //   .then((res) => {
                      //     if (res.status_code === 200) {
                      //       setComponentValue(5);
                      //     }
                      //   })
                      //   .catch((err) => {
                      //   });
                    }
                  }}
                >
                  {({ handleSubmit }) => (
                    <form
                      className="space-y-6"
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <div className="mt-1">
                        <Input
                          id="email_number"
                          name="email_number"
                          type="text"
                          autoComplete="email_number"
                          placeHolder="Enter email address"
                          className="text-center"
                          hideLabel={true}
                        />
                      </div>
                      <div>
                        <div className="flex items-center gap-5 justify-start">
                          <Button
                            className="border-red-500 w-[170px] text-black-500 hover:bg-red-700"
                            title="Back To Login"
                            type="button"
                            btnType="filled"
                            onClick={() => router("/login")}
                          />
                          <Button
                            type="submit"
                            className={`w-[170px] `}
                            title={"Next"}
                          />
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
