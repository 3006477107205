import React, { useState } from "react";
import VerifyEmail from "./VerifyEmail";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import SuccessMessage from "./SuccessMessage";

export default function Forgot() {
  const [componentValue, setComponentValue] = useState(1);

  let data = {
    setComponentValue: setComponentValue,
  };
  const renerViewBaseOnValue = (componentValue) => {
    let component;
    switch (componentValue) {
      case 1:
        component = <ForgotPassword {...data} />;
        break;
      case 2:
        component = <VerifyEmail {...data} />;
        break;
      case 3:
        component = <ResetPassword {...data} />;
        break;
      case 4:
        component = <SuccessMessage {...data} />;
        break;
      default:
    }
    return component;
  };

  // main view
  return <>{renerViewBaseOnValue(componentValue)}</>;
}
